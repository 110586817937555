import JshModule from '@core/scripts/helper/jsh-module';
import {showPictureAtVideoError} from "./video-fallback.js";
import "@mux/mux-player";

const BREAKPOINT_DESKTOP = 768;

/**
 * only for overridable videos, i.e. focus Teaser where Video can be overridden on desktop
 **/
window.addEventListener('DOMContentLoaded', () => {

    do {

        const {moduleScope} = JshModule('teaser/video-loader-overridable');
        if (moduleScope !== null) {

            const classes = moduleScope.getAttribute('class');
            const mobilePlaybackId = moduleScope.getAttribute('mobile');
            const overridePlaybackId = moduleScope.getAttribute('override');
            let usedPlaybackId;

            if (window.innerWidth >= BREAKPOINT_DESKTOP && overridePlaybackId) {
                usedPlaybackId = overridePlaybackId;
            } else {
                usedPlaybackId = mobilePlaybackId;
            }

            const muxVideo = document.createElement("mux-player");
            muxVideo.setAttribute('playback-id', usedPlaybackId)
            muxVideo.setAttribute('class', `content-platform__mux-video ${classes}`)
            muxVideo.setAttribute('stream-type', 'live')
            muxVideo.setAttribute('default-stream-type', 'live')
            muxVideo.setAttribute('loop', '')
            muxVideo.setAttribute('autoplay', '')
            muxVideo.setAttribute('muted', '')
            muxVideo.setAttribute('disable-cookies', '')
            muxVideo.setAttribute('disable-tracking', '')

            muxVideo.addEventListener("error", () => {
                showPictureAtVideoError(muxVideo);
            });

            moduleScope.replaceWith(muxVideo);
        }
    } while (document.querySelector('[jsh-module="teaser/video-loader-overridable"]') !== null);

});

